import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Button } from '@material-ui/core'
import { Layout, Page, ErrorComp } from "../components";
import { useCartData, useAuthData, useCheckoutData } from '../helpers';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from "gatsby";


const Checkout = ({ classes }) => {
  const [resultData, setResultData] = useState('');

  const cartData = useCartData();
  const checkoutData = useCheckoutData();
  const authData = useAuthData();

  useEffect(() => {
    if (checkoutData?.orderStatus) {
      setResultData(checkoutData?.orderStatus);
      cartData?.clearCart();
    }
  }, [checkoutData?.orderStatus])

  return (
    <Layout>
      <Page>
        <Box paddingY={5} className='resultPage'>
          <Box display='flex' justifyContent='center' height='50vh' flexDirection='column' alignItems='center'>
            {resultData ? <>
              <Typography variant='h6'>Order placed successfully!</Typography>
              <Box paddingTop={3} display='flex'>
                <Link to='/order-history'><Button variant='contained' color='secondary'>Go to order history</Button></Link>
                <Box width={6} />
                <Link to='/'><Button variant='contained' color='secondary'>Back to shopping</Button></Link>
              </Box>
            </> : <ErrorComp resultPage/>}
          </Box>
        </Box>
      </Page>
    </Layout>
  )
}

export default Checkout
